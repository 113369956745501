import React, { Component } from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import styles from './career.module.sass'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const options = {
   renderMark: {
      [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
   },
   renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
         return <h1 className={styles.heading_1}>{children}</h1>
      },
   },
}

class Career extends Component {
   render() {
      const { name, longDescription, company } =
         this.props.data.contentfulCareer

      return (
         <Layout>
            <SEO title={name} />
            <div className={['page', styles.page__career].join(' ')}>
               <div className={['page--header', styles.page__header].join(' ')}>
                  <h1 className={['page--title', styles.page__title].join(' ')}>
                     {name}
                  </h1>
               </div>
               <p className={styles.subheader}>{company}</p>
               <div className={styles.content}>
                  {renderRichText(longDescription, options)}
               </div>
            </div>
         </Layout>
      )
   }
}

Career.propTypes = {
   data: propTypes.object.isRequired,
}

export default Career

export const pageQuery = graphql`
   query careerQuery($slug: String!) {
      contentfulCareer(slug: { eq: $slug }) {
         name
         slug
         company
         shortDescription {
            childMarkdownRemark {
               html
               timeToRead
            }
         }
         longDescription {
            raw
         }
      }
   }
`
